import React, { useState } from 'react';
import Hero from '../components/sections/Hero';
import { Parallax } from 'react-parallax';
import PropTypes from 'prop-types';

//Images
import loginBgImage from '../assets/images/paralla/fractal.jpg';

//API URL
const API_REGISTER = 'http://localhost:8080/register'

//Gestion du formulaire - 04-03-2021
async function registerUser(credentials) {
    return fetch(API_REGISTER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(res => {
          if(res.status >= 400) {
              throw new Error ("Server responds with error !");
          }
          return res.json();
         })
}

export default function Register ({setToken}) {
    const [name, setName] = useState();
    const [firstname, setFirstname] = useState();
    const [company, setCompany] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();


    const handleSubmit = async e => {
        e.preventDefault();
        const token = await registerUser({
            name,
            firstname,
            company,
            email,
            password
            });
        setToken(token);
      }


    return(
        <>
        <Hero className="illustration-section-01" />  
        <Parallax 
            blur={{ min: 15, max: -15 }} 
            bgImageStyle={{
                opacity:'.4',
                width:'100%'}}           
            bgImage={loginBgImage} 
            bgImageAlt="Fond Tech Parallax" 
            strength={300}
        >
            <div className="login-wrapper">
                
                <form onSubmit={handleSubmit}>
                <h2>Nouveau compte client</h2>
                <fieldset>
                <div>
                    <label className="login-labelStyle">
                        <p>Nom</p>
                        <input
                        className="login-form-control"
                        type="name"
                        placeholder="Votre nom"
                        onChange={e => setName(e.target.value)}
                        />
                    </label>                
                </div>
                <div>
                    <label className="login-labelStyle">
                        <p>Prénom</p>
                        <input
                        className="login-form-control"
                        type="firstname"
                        placeholder="Votre prénom"
                        onChange={e => setFirstname(e.target.value)}
                        />
                    </label>                
                </div>
                <div>
                    <label className="login-labelStyle">
                        <p>Entreprise (facultatif)</p>
                        <input
                        className="login-form-control"
                        type="company"
                        placeholder="Votre entreprise"
                        onChange={e => setCompany(e.target.value)}
                        />
                    </label>                
                </div>
                <div>
                    <label className="login-labelStyle">
                        <p>Email</p>
                        <input
                        className="login-form-control"
                        type="email"
                        placeholder="Votre email"
                        onChange={e => setEmail(e.target.value)}
                        />
                    </label>
                    
                </div>
                <div >
                    <label className="login-labelStyle">
                        <p>Mot de passe</p>
                        <input
                            className="login-form-control"
                            type="password"
                            placeholder="Saisissez un mot de passe"
                            onChange={e => setPassword(e.target.value)}
                        />
                    </label>
                    
                </div>
                <div >
                    <label className="login-labelStyle">
                        <p>Confirmation du mot de passe</p>
                        <input
                            className="login-form-control"
                            type="password"
                            placeholder="Recopiez votre mot de passe"
                        />
                    </label>
                </div>
                <button 
                    type="submit" 
                    class="btnSbmit">
                    S'enregistrer
                </button>
                </fieldset>
                </form>
                <p >Vous avez déjà un compte ? <a href="/Login">Se connecter</a></p>
            </div>
            </Parallax>
        </>
    );
}

Register.propTypes = {
setToken: PropTypes.func.isRequired
}