import React from 'react';
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
//import Cta from '../components/sections/Cta';
//import FeaturesSplit from '../components/sections/FeaturesSplit';
//import ParallaxBackground from '../components/sections/ParallaxFeaturesTiles';
//import ParallaxFeaturesTiles from '../components/sections/ParallaxFeaturesTiles';
//import ParallaxTestimonial from '../components/sections/ParallaxTestimonial';
//import sections
//import Testimonial from '../components/sections/Testimonial';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles className="f-t-paral" />
    </>
  );
}

export default Home;