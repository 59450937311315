import React from "react";
import Hero from '../../components/sections/Hero';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import "./Developpement.css";

const Developpement = () => {

  return (
    <>
      <Hero className="illustration-section-01" /> 
  
 <div className="developpement-text-style">
    <h2>Nos offres de développement</h2>
    <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />  
  </div>
  </>
  )
};

export default Developpement;