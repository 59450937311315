import React from 'react';
import Hero from '../components/sections/Hero';
import './styles/Support.css';

//Images
import logoNovaMedium from '../assets/images/novalogo_N_White.png';

const Support = () => {

  return (
    <>
      <Hero className="illustration-section-01" />  
      <div className="divMainTxt">
            <h2>Support</h2>
            <p>Nos services sont ouverts du <b>lundi au vendredi de 9h à 19h</b></p>
            
            <p>En cas de besoin merci de nous contacter</p>
            <ul className="coordStyle">
              <li>Par mail : <a href="mailto:support@novacyb.com" className="aSupStyle">support@novacyb.com</a></li>
              <li>Par téléphone : <a className="aSupStyle">06.49.61.24.39</a></li>
            </ul>
            <img className="imgAboutUs" src={logoNovaMedium} alt="Logo Novacyb"/>


        </div>
    </>
  );
}

export default Support;