import React from "react";
import Hero from '../../components/sections/Hero';

//Style
import "./Maintenance.css";

const Maintenance = () => {
  return(
    <>
    <Hero className="illustration-section-01" /> 
<div className="container maintenance-text-format">

          <h2>MAINTENANCE</h2>
            <div> 
                <h5 >Désinfection virus</h5>
        
                <div >
                    <p> Nettoyage en profondeur de l'ordinateur après infection et prévention.<br/>
                    <strong className="prices-price">Prix : 79 €</strong></p>
                </div>
                <h5 > Réinstallation de Windows© et MacOS©</h5>
                <div >
                    <p> Remise à l'état de départ de l'ordinateur. Pour MacOS, la dernière version du sytème d'exploitation installée sera rétablie.<br/>
                    <strong className="prices-price">Prix : 79 €</strong></p>
                </div>	

                <h5 > Réinstallation de Windows© et MacOS© avec récupération de données </h5>        
                <div >
                    <p> Remise à l'état de départ de l'ordinateur. Pour MacOS, la dernière version du sytème d'exploitation installée sera rétablie.
                    <br />Avant la réinstallation de votre système nous sauvegardons vos données récupérables afin de les transférer sur le nouveau. <br/>
                    <strong className="prices-price">Prix : 99 €</strong></p>
                </div>
        
                <h5 > Récupération / Sauvegarde / Transfert de données </h5>
                <div >
                    <p> Pour ne plus perdre vos données, demandez une sauvegarde ou un transfert de vos données. Si vous pensez avoir perdu des fichiers nous pouvons tenter une récupération de données.<br/>
                    <strong className="prices-price">Prix : 49 €</strong></p>
                </div>
        
                <h5 > Installation / Remplacement de disque dur </h5>
                <div >
                    <p> Au démarrage votre ordinateur ne trouve pas le disque dur ? Un remplacement est nécessaire. Après le montage du matériel, nous devrons réinstaller le système Windows© pour que le PC soit opérationnel. 
                        <br/>Le prix dépend de la capacité du Disque Dur et de la nécessité de réinstaller le système.
                        <br/><strong className="prices-price">À partir de 59 €</strong> </p>
                </div>									
            </div>	

            <div> 
                <h5 > Ajout / remplacement de mémoire vive </h5>
                <div >
                    <p> Besoin de vitesse et d'une meilleure gestion du multitâches ? Ajoutez de la mémoire RAM ! <br/>
                    <strong className="prices-price">À partir de 49 €</strong></p>
                </div>
                <h5 > Remplacement lecteur/graveur CD/DVD/Bluray</h5>
                <div >
                    <p> Votre lecteur/graveur CD/DVD/Bluray ne fonctionne plus ou vous souhaitez juste passer d'un simple graveur DVD à un lecteur/graveur Bluray ? Nos équipes s'en chargent.<br/>
                    <strong className="prices-price">À partir de 49 €</strong> </p>
                </div>	
                <h5 > Nettoyage / dépoussiérage </h5>
                <div >
                    <p> <strong>Un nettoyage complet de votre ordinateur</strong> afin d'améliorer la ventilation et d'éviter une éventuelle surchauffe.<br/>
                    <strong className="prices-price">Prix : 59 €</strong> </p>
                </div>
                <h5 > Autres pannes ? Nous contacter </h5>
                <div >
                    <p> Contactez-nous au <strong className="prices-price">06 49 61 24 39</strong> ou via <a href="/contact">le formulaire de contact.</a> </p>
                </div>							
            </div>	                            
</div>
</>
  );
}

export default Maintenance;