import React from 'react';
import Hero from '../components/sections/Hero';
//import Cta from '../components/sections/Cta';

//Team
import Team from '../components/sections/categories/team';

//Slider
import Slider from '../components/elements/Carousel';

//Styles
import "./styles/AboutUs.css";

//Images
import ansibleLogo from "../assets/images/otherLogo/Ansible_logo.png";
import javaLogo from "../assets/images/otherLogo/Java_Logo.png";
import wordpressLogo from "../assets/images/otherLogo/WordPress_logo.png";
import csharpLogo from "../assets/images/otherLogo/C_Sharp.png";
import reactLogo from "../assets/images/otherLogo/React-icon.png";
import nodeLogo from "../assets/images/otherLogo/Node.js_logo.png";
import Logo4d from "../assets/images/otherLogo/Logo-4D-new.jpg";
import phpLogo from "../assets/images/otherLogo/PHP-logo.png";

//import bgfractal from "../assets/images/paralla/fractal.jpg";

const slideData = [
  {
    index: 0,
    headline: '',
    button: 'Ansible',
    src: ansibleLogo
  },
  {
    index: 1,
    headline: '',
    button: 'Java',
    src: javaLogo
  },
  {
    index: 2,
    headline: '',
    button: 'Wordpress',
    src: wordpressLogo
  },
  {
    index: 3,
    headline: '',
    button: 'C Sharp',
    src: csharpLogo
  },
  {
    index: 4,
    headline: '',
    button: 'React JS',
    src: reactLogo
  },
  {
    index: 5,
    headline: '',
    button: 'Node',
    src: nodeLogo
  },
  {
    index: 6,
    headline: '',
    button: '4D',
    src: Logo4d
  },
  {
    index: 7,
    headline: '',
    button: 'PHP',
    src: phpLogo
  }
]

const AboutUs = () => {

  return (
    <>
        <Hero className="illustration-section-01" />  
        <div className="container divMainTxt">
            <h1>A propos de Novacyb </h1>
                <Team/>
              <h2>Technologies</h2>
                <div className="div-aboutus-techno">
                  <Slider heading="Technologie" slides={slideData} />
                </div>
        </div>
      <br/>
    </>
  );
}

export default AboutUs;