import React from 'react';
import { Switch, Route, BrowserRouter as Router, BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

// Views 
import Home from './views/Home';
import Contact from './views/Contact';
import AboutUs from './views/AboutUs';
import FAQ from './views/Faq';
import Support from './views/Support';
import Developpement from './views/category/Developpement';
import Maintenance from './views/category/Maintenance';
import Training from './views/category/Training';
import Error from './views/Error';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

import NewAccount from './views/NewAccount';
import Mentions from './views/Mentions';
import Prices from './views/Prices';
import CookiePolicy from './views/Cookie-policy';

//Login & Register
import Login from './views/Login';
import Register from './views/Register';

//Logged
import Dashboard from './back/views/Dashboard';
import Preferences from './back/views/Preferences';


//Token
import useToken from './useToken';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.pageview(window.location.pathname + window.location.search);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

function App(){
  const { token, setToken } = useToken();
  if(!token) {
    return (
      <Router>
          <Header/>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/AboutUs" component={AboutUs} />
            <Route path="/Contact" component={Contact} />
            <Route path="/Cookie-policy" component={CookiePolicy} />
            <Route path="/Developpement" component={Developpement} />
            <Route path="/FAQ" component={FAQ} />
            <Route path="/Training" component={Training} />
            <Route path="/Home" component={Home} />
            <Route path="/Login"><Login setToken={setToken}/></Route>
            <Route path="/Maintenance" component={Maintenance} />
            <Route path="/Mentions" component={Mentions} />
            <Route path="/NewAccount" component={NewAccount} />
            <Route path="/Prices" component={Prices} />
            <Route path="/Register"><Register setToken={setToken}/></Route>
            <Route path="/Support" component={Support} />         
            <Route component={Error} />
          </Switch>
          <Footer/>
      </Router>
    );    
  }
  return (
    <div className="wrapper">
    <h1>Espace Client</h1>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Dashboard/>
          </Route>
          <Route path="/Dashboard">
            <Dashboard/>
          </Route>
          <Route path="/Preferences">
            <Preferences/>
          </Route>
          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    </div>
  )


}

export default App;