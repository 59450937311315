import React from 'react';
import Hero from '../components/sections/Hero';
import './styles/Mentions.css';

const Mentions = () => {

return(

<div class="container">
            <div class="content content-full-width m-legales-global-text">
			<Hero className="illustration-section-01" />
                <h1 className="m-legales-main-title"> <span >Mentions Légales</span> </h1>
                <h6>Novacyb – Les technologies modernes au service de vos projets ! </h6>
                                        <div class="post-content">
                                        <p><strong>Nom ou Raison sociale :</strong> <span className="m-legales-texte">NOVACYB SAS</span>  
		                        <br/><strong>Adresse électronique :</strong> <span className="m-legales-texte">contact@novacyb.com</span>
		                        <br/><strong>Téléphone :</strong> <span className="m-legales-texte">06 49 61 24 39</span>
		                        <br/><strong>N° Registre Commerce :</strong> <span className="m-legales-texte">893 922 195</span>
		                        <br/><strong>Adresse siège social :</strong> <span className="m-legales-texte">5, impasse de la Chênaie – 69140 Rillieux-la-Pape</span></p>

		                        <p><br/>L'hébergement de ce site est assuré par PLANET HOSTER:</p>
 
		                        <p className="m-legales-texte"><strong>PLANET HOSTER</strong>
		                        <br/>PlanetHoster <br/> 4416 Louis-B.-Mayer <br/> Laval, Québec <br/> Canada H7P 0G1</p>

		                        <p className="m-legales-texte"><br/>L'ensemble de ce site relève des législations française et internationale sur le droit d'auteur et la propriété intellectuelle. 
		                        <br/>Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques et photographiques.</p>
		  
		                        <p><br/>Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés 
		                        <br/>Version consolidée au 27 août 2011
		                        <br/></p><center>Article 38 
		                        <br/>Modifié par Loi n°2004-801 du 6 août 2004 - art. 5 JORF 7 août 2004</center>
		                        <br/><i>"Toute personne physique a le droit de s'opposer, pour des motifs légitimes, à ce que des données à caractère personnel la concernant fassent l'objet d'un traitement.
		                        <br/>Elle a le droit de s'opposer, sans frais, à ce que les données la concernant soient utilisées à des fins de prospection, notamment commerciale, par le responsable actuel du traitement ou celui d'un traitement ultérieur.
		                        <br/>Les dispositions du premier alinéa ne s'appliquent pas lorsque le traitement répond à une obligation légale ou lorsque l'application de ces dispositions a été écartée par une disposition expresse de l'acte autorisant le traitement."</i><p></p>											
                </div>
            </div>
        </div>
	)
}

export default Mentions;