import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
/*import SectionHeader from './partials/SectionHeader';*/

//Images
/*import LogoNova from './../../assets/images/novalogo_N_White.png';*/
import website from './../../assets/images/platform.jpg';
import learn from './../../assets/images/LogoRond_Learn.png';
import tools from './../../assets/images/techsup.png';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  /*const sectionHeader = {
    title: 'Vos envies',
    paragraph: 'Nos solutions'
  };*/

  return (
    <div class="container f-tiles-main-background">
    <section
      {...props}
      className={outerClasses}
    >
        <div className="f-tiles-box">
        <div className={innerClasses}>

          <div className="f-tiles-section-title">
            <p className="txt-section-title">Informatique & Développement</p>
            <p className="txt-section-sub-title">Développement - Formation - Maintenance</p>
          </div>

          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
            <a href="/Developpement">
              <button className="btn-home-f-tiles">
                <div className="features-tiles-item-image mb-16">
                        
                        <img
                          className="logo-tiles-webs"
                          src={website}
                          alt="Light" />
                      
                    </div>
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-content">
                      <h4 className="f-t-item-title">
                      Développement <br/> Sites Internet <br/> Applications
                        </h4>
                    </div>

                  </div>
                
              </button>
              </a>
            </div>
            <div className="tiles-item reveal-from-bottom">
            <a href="/Contact">
              <button className="btn-home-f-tiles">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <img
                        className="logo-tiles"
                        src={learn}
                        alt="Think" />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="f-t-item-title">
                      Formation <br/> Logiciels <br/> Matériels
                      </h4>
                  </div>
                </div>
              </button>
              </a>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
            <a href="/Maintenance">
              <button className="btn-home-f-tiles">
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <img
                          className="logo-tiles-webs" 
                          src={tools}
                          alt="Tools" />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="f-t-item-title">
                        Dépannage <br/> Maintenance <br/> Assemblage
                        </h4>
                    </div>
                  </div>
              </button>
              </a>
            </div>

            

          </div>
        </div>
        </div>

    </section>
    </div>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;