import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

//Images
import shopImage from './../../assets/images/shop-3.jpg';
import saasImage from './../../assets/images/saas-2.jpg';
import brandImage from './../../assets/images/brand.jpg';


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Apprenons à nous connaître',
    paragraph: "Votre personnalité est unique. Votre besoin est spécifique."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container container-background-dev">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <h3>Apprenons à nous connaître</h3>
            <h4>Votre personnalité est unique. Votre besoin est spécifique.</h4>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="fw-600 tt-u mb-8">
                  Sites e-commerce
                  </div>
                <h3 className="mt-0 mb-12">
                  Des boutiques attrayantes
                  </h3>
                <p className="m-0">
                Vos clients sont au cœur de votre univers : aidez-les à s’identifier dans vos créations tout en conservant votre personnalité. Nous analyserons ensemble l’expérience utilisateur idéale en vous accompagnant à chaque étape, si vous le désirez.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                  <img 
                    className="shopImage-style" 
                    src={shopImage} 
                    alt="shop"/>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="fw-600 tt-u mb-8">
                  Application Web
                  </div>
                <h3 className="mt-0 mb-12">
                  "SAAS : Software As A Service" Des outils pour vos besoins
                  </h3>
                <p className="m-0">
                  Vos outils accessibles partout, tout le temps, sur tous les supports et en toute sécurité. Vous n'avez plus besoin d'investir dans des équipements hors de prix pour vos équipes.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={saasImage}
                  alt="Features split 02"
                  max-width={528}
                  max-height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="fw-600 tt-u mb-8">
                  Site commercial, blog, etc.
                  </div>
                <h3 className="mt-0 mb-12">
                  Une communication claire ciblée et fonctionnelle
                  </h3>
                <p className="m-0">
                  Votre site porte votre identité aux yeux du monde. Maîtrisez votre image et propulsez vos capacités de communication vers de nouveau prospects jusqu'alors inaccessibles. Optimisez votre référencement et gagnez de nouveaux clients.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={brandImage}
                  alt="Features split 03"
                  max-width={528}
                  max-height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;