import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import "./styles/Faq.css";
import { Link } from 'react-router-dom';
//import FeaturesTiles from '../components/sections/FeaturesTiles';
//import FeaturesSplit from '../components/sections/FeaturesSplit';
//import Testimonial from '../components/sections/Testimonial';
//import ParallaxBackground from '../components/sections/ParallaxFeaturesTiles';
//import ParallaxFeaturesTiles from '../components/sections/ParallaxFeaturesTiles';
//import ParallaxTestimonial from '../components/sections/ParallaxTestimonial';

const FAQ = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      
    <div class="content_wrap">
        <h3 className="faq-main-title">Foire aux questions</h3>
        <ol>
            <li>
                <h5>Combien coûte le développement d'un site internet ou d'une application ?</h5>
                <p>Le développement d'un site internet ou d'une application est tout d'abord structuré sous forme d'un cahier des charges afin de valider le projet de départ. Une fois ce projet validé, un devis complet peut-être établi basé sur le temps et les ressources nécessaires à sa réalisation.</p>
            </li>
            <li>
                <h5>Sous quelle(s) forme(s) sont dispensées les formations ?</h5>
                <p>Les formations sont dispensées soit en distanciel ou directement sur place, selon les besoins.</p>
            </li>
            <li>
                <h5>Est-il possible de faire un dépannage sur site ?</h5>
                <p>Oui. Cependant, avant tout dépannage, une estimation du type de panne sera faite pour confirmer la nécessité et l'urgence du déplacement. Un dépannage à distance, lorsqu'il est possible, sera toujours plus avantageux financièrement.</p>
            </li>
            <li>
                <h5>Qui a créé le projet Novacyb ?</h5>
                <p>Créée en 2021, <b>Novacyb SAS</b> est une jeune entreprise de développement,  de maintenance et de formation. Elle est représentée par M Romuald SENIGALLIA qui intervent sur l'ensemble de ces domaines en collaboration avec des partenaires en cas de nécessité. <Link to="/AboutUs">En savoir plus...</Link></p>
            </li>
        </ol>
    </div>
    </>
  );
}

export default FAQ;