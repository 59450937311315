import React from 'react';
import Hero from '../components/sections/Hero';
import Cta from '../components/sections/Cta';
import "./styles/Prices.css";
import { Link } from 'react-router-dom';

const Prices = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      
      <h2 className="prices-main-title">TARIFS</h2>
    <div class="content_wrap">
        <div className="prices-text">
            <h3>DEVELOPPEMENT</h3>
            <p>Sur devis uniquement</p>
            
            <h3>MAINTENANCE</h3>
            <div> 
                <h5 >
                    <a> Désinfection virus </a>
                </h5>
        
                <div >
                    <p> Nettoyage en profondeur de l'ordinateur après infection et prévention.<br/>
                    <strong className="prices-price">Prix : 79 €</strong></p>
                </div>
                <h5 ><a> Réinstallation de Windows© et MacOS©</a></h5>
                <div >
                    <p> Remise à l'état de départ de l'ordinateur. Pour MacOS, la dernière version du sytème d'exploitation installée sera rétablie.<br/>
                    <strong className="prices-price">Prix : 79 €</strong></p>
                </div>	

                <h5 ><a> Réinstallation de Windows© et MacOS© avec récupération de données </a></h5>        
                <div >
                    <p> Remise à l'état de départ de l'ordinateur. Pour MacOS, la dernière version du sytème d'exploitation installée sera rétablie.
                    <br />Avant la réinstallation de votre système nous sauvegardons vos données récupérables afin de les transférer sur le nouveau. <br/>
                    <strong className="prices-price">Prix : 99 €</strong></p>
                </div>
        
                <h5 ><a> Récupération / Sauvegarde / Transfert de données </a></h5>
                <div >
                    <p> Pour ne plus perdre vos données, demandez une sauvegarde ou un transfert de vos données. Si vous pensez avoir perdu des fichiers nous pouvons tenter une récupération de données.<br/>
                    <strong className="prices-price">Prix : 49 €</strong></p>
                </div>
        
                <h5 ><a> Installation / Remplacement de disque dur </a></h5>
                <div >
                    <p> Au démarrage votre ordinateur ne trouve pas le disque dur ? Un remplacement est nécessaire. Après le montage du matériel, nous devrons réinstaller le système Windows© pour que le PC soit opérationnel. 
                        <br/>Le prix dépend de la capacité du Disque Dur et de la nécessité de réinstaller le système.
                        <br/><strong className="prices-price">À partir de 59 €</strong> </p>
                </div>									
            </div>	

            <div> 
                <h5 ><a> Ajout / remplacement de mémoire vive </a></h5>
                <div >
                    <p> Besoin de vitesse et d'une meilleure gestion du multitâches ? Ajoutez de la mémoire RAM ! <br/>
                    <strong className="prices-price">À partir de 49 €</strong></p>
                </div>
                <h5 ><a> Remplacement lecteur/graveur CD/DVD/Bluray</a></h5>
                <div >
                    <p> Votre lecteur/graveur CD/DVD/Bluray ne fonctionne plus ou vous souhaitez juste passer d'un simple graveur DVD à un lecteur/graveur Bluray ? Nos équipes s'en chargent.<br/>
                    <strong className="prices-price">À partir de 49 €</strong> </p>
                </div>	
                <h5 ><a> Nettoyage / dépoussiérage </a></h5>
                <div >
                    <p> <strong>Un nettoyage complet de votre ordinateur</strong> afin d'améliorer la ventilation et d'éviter une éventuelle surchauffe.<br/>
                    <strong className="prices-price">Prix : 59 €</strong> </p>
                </div>
                <h5 ><a> Autres pannes ? Nous contacter </a></h5>
                <div >
                    <p> Contactez-nous au <strong className="prices-price">06 49 61 24 39</strong> ou via le formulaire de <Link to="/Contact">contact</Link>. </p>
                </div>							
            </div>	                        
        </div>       
    </div>
            
    </>
  );
}

export default Prices;