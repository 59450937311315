import React, { Component,useReducer, useState } from 'react';
import './styles/NewAccount.css';
import Hero from '../components/sections/Hero';
import { Parallax, Background } from 'react-parallax';

export default class NewAccount extends Component {

    render() {
        return (
            <>
            <Hero className="illustration-section-01" />  
            <Parallax blur={{ min: 15, max: -15 }} bgImage={require('../assets/images/glass-1.jpg')} bgImageAlt="Fond Tech Parallax" strength={300}>
            
            <div className="wrapper">
                <form className="signUpForm">
                    <h3>S'inscrire</h3>
                        <fieldset>
                            <label className="labelStyle"><p>Prénom</p> <input type="text" className="form-control" placeholder="Prénom" /></label>
                            <label className="labelStyle"><p>Nom</p> <input type="text" className="form-control" placeholder="Nom" /></label>   
                            <label className="labelStyle"><p>Adresse email</p><input type="email" className="form-control" placeholder="Entrez votre email" /> </label>                  
                            <label className="labelStyle"><p>Mot de passe</p><input type="password" className="form-control" placeholder="Entrez votre mot de passe" /></label>
                            <div>
                                <button type="submit" className="btnSbmit">S'inscire</button>
                            </div>
                        </fieldset>
                    <p className="forgot-password text-right">
                        Déjà un compte <a href="#">Se connecter ?</a>
                    </p>
                </form>
            </div>
            </Parallax>
            </>
        );
    }
}