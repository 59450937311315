import React from 'react';
import classNames from 'classnames';
import { Route, Link } from 'react-router-dom';
import Image from '../../elements/Image';

//Images
import logoWhite from './../../../assets/images/FullTitle.png';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Route>
          <Link to="/Home">
            <img
              src={logoWhite}
              alt="Logo"
              width={150} />
          </Link>
        </Route>
      </h1>
    </div>
  );
}

export default Logo;