import React from 'react';

//images
import rsenig from '../../../assets/images/RomSen1.JPG';
import linkedinLogo from "../../../assets/images/Linkedin.svg";

const team = () => {
return(
    <div>
    <h2>Equipe</h2>
    <p>Président et fondateur de la société</p>
    <span> Romuald SENIGALLIA</span>
    <img className="imgAboutUs" src={rsenig} alt="Romuald Senigallia"/>
    <p> Me joindre : <a href="mailto:contact@novacyb.com">contact@novacyb.com</a></p>        
    <div className="socialDiv">
        <p className="row">Suivez-moi sur <a href="https://www.linkedin.com/in/romuald-senigallia/" target="_blank" rel="noreferrer">Linked</a> </p>
        <a href="https://www.linkedin.com/in/romuald-senigallia/" target="_blank" rel="noreferrer"> <img  className="socialNet" src={linkedinLogo} alt="Logo LinkedIn"/></a>
    </div>
    </div>
    )
}

export default team;
