import React from 'react';
import ReactDOM from 'react-dom';
import Form from '../components/sections/Form/index';
import Hero from '../components/sections/Hero';
import './styles/Contact.css';

const Contact =() => {

    return(
        <div>
            <Hero className="illustration-section-01"/>
            <div className="contact-div-main-txt">
              <Form />
              <br/>
              <p>Appelez-nous au <span>06 49 61 24 39</span></p>
              <p>Ecrivez-nous à <a href="mailto:contact@novacyb.com">contact@novacyb.com</a></p>
            </div>
        </div>
    )
    };
export default Contact;