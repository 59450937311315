import React from 'react';
import Hero from '../components/sections/Hero';
import "./styles/Cookie-policy.css";
import { Link } from 'react-router-dom';

const CookiePolicy = () => {

    return (
    <>
            <Hero className="illustration-section-01" />

            <h2 className="prices-main-title">POLITIQUE DE GESTION DES COOKIES</h2>
            <div className="rte">

                <br/>
                    <p>Lors de la consultation du Site, des Cookies sont déposés sur le terminal de l’Utilisateur.</p>
                    <p>Via cette politique de Cookies, l’Utilisateur peut s’informer sur la nature des Cookies déposés et leur fonctionnement.</p>
                    <p><strong><u>Article 1 : Définitions </u></strong></p>
                    <p>- Site : ensemble de pages web et de ressources reliées par des hyperliens, défini et accessible par une adresse web.</p>
                    <p>- Utilisateur : désigne toute personne qui accède et navigue sur le Site soit en tant que simple internaute soit en tant que Client.</p>
                    <p>- Cookie : désigne un fichier texte déposé sur le disque dur du terminal de l’Utilisateur lors de sa viSite sur un Site internet.</p>
                    <p>Par conséquent, un Cookie est une suite d'informations qui peut être transmis à un navigateur par un Site web sur lequel l’Utilisateur se connecte.</p>
                    <p>Le navigateur web le conserve pendant une certaine durée, et le renvoie au serveur web chaque fois que l’Utilisateur se connecte.</p>
                    <p>Les Cookies ont de multiples usages : ils peuvent servir à mémoriser un identifiant client auprès d'un Site marchand, le contenu d’un panier d'achat, un identifiant permettant de tracer une navigation pour des finalités statistiques ou publicitaires.</p>
                    <p>La loi dispose qu’il ne peut être stockée des Cookies sur l’appareil de l’Utilisateur que s’ils sont strictement nécessaires au fonctionnement du Site. Pour tous les autres types de Cookies, il est nécessaire de recueillir le consentement.</p>
                    <p>Ce Site utilise différents types de Cookies. Certains Cookies sont placés par les services tiers qui apparaissent sur les pages. À tout moment, l’Utilisateur peut modifier ou retirer son consentement dès la Déclaration relative aux Cookies sur le Site Web.</p>
                    
                    <p><strong><u>Article 2 : les différents types de Cookies</u></strong></p>
                    <ul>
                        <li>Les Cookies de navigation&nbsp;:</li>
                    </ul>
                    <p>Il s'agit des Cookies nécessaires au fonctionnement du Site.</p>
                    <p>Ils permettent d'utiliser les principales fonctionnalités du Site (par exemple utilisation d'un éventuel panier d'achat ou l'accès au compte).</p>
                    <p>Il s'agit de Cookies déposés par le propriétaire du Site qui ne concernent que le fonctionnement de celui-ci et qui ne requièrent pas l'information et l'accord préalable de l'Utilisateur pour être déposés sur son terminal.</p>
                    <ul>
                        <li>Les Cookies analytiques :</li>
                    </ul>
                    <p>Il s'agit des Cookies qui permettent de connaître l'utilisation, les volumes de fréquentation et d'utilisation ainsi que les performances du Site.</p>
                    <p>Ces Cookies permettent au propriétaire du Site d'améliorer l'intérêt, l'ergonomie et le fonctionnement des services proposés sur le Site.</p>
                    <ul>
                        <li>Les Cookies fonctionnels :</li>
                    </ul>
                    <p>Il s'agit des Cookies qui permettent d'identifier les informations du Site qui pourraient intéresser l’Utilisateur afin de personnaliser son expérience sur le Site. Ils permettent également à l’Utilisateur de bénéficier de conseils personnalisés et d'offres promotionnelles en fonction de l’origine de la navigation (par exemple si l’Utilisateur vient des Sites partenaires).</p>
                    <p>Ils peuvent aussi être utilisés pour fournir des fonctionnalités sollicitées par l’Utilisateur. Afin de personnaliser l’expérience d'achat de l’Utilisateur, le propriétaire du Site peut associer des données de navigation traitées par les Cookies avec les données des bases clients relatives au compte de l’Utilisateur ou aux achats sur le Site.</p>
                    <p>Il peut être déposé un Cookie de géolocalisation sur le Site, afin de localiser l’Utilisateur pour permettre de bénéficier de certaines fonctionnalités telles que la recommandation d'adresses de magasins, l'affichage des produits disponibles dans les magasins les plus proches ou encore la prise de rendez-vous.</p>
                    <p>Les Cookies de géolocalisation sont conservés pour une durée limitée aux besoins du service.</p>
                    <ul>
                        <li>Les Cookies publicitaires</li>
                    </ul>
                    <p>Il s'agit des Cookies utilisés pour présenter des publicités à l’Utilisateur ou adresser des informations adaptées aux centres d'intérêts de l’Utilisateur sur le Site ou en dehors du Site lors de la navigation sur Internet.</p>
                    <p>Ils sont notamment utilisés pour limiter le nombre de fois où l’Utilisateur voit une publicité et aider à mesurer l'efficacité d'une campagne publicitaire.</p>
                    <p>Ils permettent, pendant la durée de validité de ces Cookies, de : diffuser de la publicité dans tous les emplacements réservés à la publicité de tiers, comptabiliser le nombre d'affichages des contenus publicitaires diffusés, d'identifier les publicités ainsi affichées et le nombre d'Utilisateurs ayant cliqué sur chaque publicité, de calculer les sommes dues de ce fait et d'établir des statistiques, reconnaître le terminal de l’Utilisateur lors de sa navigation ultérieure sur tout autre Site ou service sur lequel ces annonceurs ou ces tiers émettent également des Cookies et, le cas échéant, d'adapter ces Sites et services tiers ou les publicités qu'ils diffusent, à la navigation de votre terminal dont ils peuvent avoir connaissance.</p>
                    <p><strong><u>Article 3&nbsp;: Cookies utilisés sur le Site</u></strong></p>
                    <p>Lors de la connexion de l’Utilisateur sur le Site, une bannière s’affiche.</p>
                    <p>Sous réserve des choix effectués, des Cookies seront stockés dans la mémoire du terminal utilisé (ordinateur, tablette, téléphone). Les informations ainsi collectées peuvent être utilisées par le Site consultés ou par un tiers, telle qu’une régie publicitaire, ou tout autre partenaire.</p>
                    <p>La durée de validité du Cookie est de 13 (treize) mois maximum à compter du jour où l’Utilisateur a donné son consentement à l’intégration dudit Cookie.</p>
                    <div class="table-wrapper"><table width="602">
                        <tbody>
                            <tr>
                                <td colspan="3" width="602">
                                    <p><strong><u>Cookie Strictement nécessaire</u></strong></p>
                                    <p><strong><u>Exemple </u></strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td width="172">
                                    <p><strong>Nom du Cookie </strong></p>
                                </td>
                                <td width="171">
                                    <p><strong>&nbsp;Durée </strong></p>
                                </td>
                                <td width="259">
                                    <p><strong>&nbsp;But&nbsp; </strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td width="172">
                                    <p>ForceFlashSite</p>
                                </td>
                                <td width="171">
                                    <p>Session</p>
                                </td>
                                <td width="259">
                                    <p>Lors de l'affichage d'un Site mobile (ancien mobile sous m.domain.com), le serveur sera forcé d'afficher la version non mobile et évitera la redirection vers le Site mobile.</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="172">
                                    <p>………….</p>
                                </td>
                                <td width="171">
                                    <p>…………….</p>
                                </td>
                                <td width="259">
                                    <p>……………….</p>
                                </td>
                            </tr>
                        </tbody>
                    </table></div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div class="table-wrapper"><table width="602">
                        <tbody>
                            <tr>
                                <td colspan="5" width="602">
                                    <p><strong>Cookie de Fonctionnalité</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td width="172">
                                    <p><strong>Nom du Cookie&nbsp; </strong></p>
                                </td>
                                <td colspan="2" width="171">
                                    <p><strong>&nbsp; Durée</strong></p>
                                </td>
                                <td colspan="2" width="259">
                                    <p><strong>&nbsp;But&nbsp; </strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td width="172">
                                    <p>svSession</p>
                                </td>
                                <td colspan="2" width="171">
                                    <p>Permanent (Deux ans)</p>
                                </td>
                                <td colspan="2" width="259">
                                    <p>Identifier les viSiteurs uniques et suit les sessions d'un viSiteur sur un Site</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="172">
                                    <p>……………</p>
                                </td>
                                <td colspan="2" width="171">
                                    <p>Session</p>
                                </td>
                                <td colspan="2" width="259">
                                    <p>Indiquer comment le rendu d'un Site.</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" width="602">
                                    <p>Cookie tierce partie</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" width="260">
                                    <p><strong>&nbsp;Nom du Cookie&nbsp; </strong></p>
                                </td>
                                <td colspan="2" width="171">
                                    <p><strong>Durée</strong></p>
                                </td>
                                <td width="171">
                                    <p><strong>But&nbsp; </strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" width="260">
                                    <p>TS*</p>
                                </td>
                                <td colspan="2" width="171">
                                    <p>Session</p>
                                </td>
                                <td width="171">
                                    <p>Sécurité&nbsp;</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" width="260">
                                    <p>……..</p>
                                </td>
                                <td colspan="2" width="171">
                                    <p>Session</p>
                                </td>
                                <td width="171">
                                    <p>Sécurité</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="172"></td>
                                <td width="88"></td>
                                <td width="83"></td>
                                <td width="88"></td>
                                <td width="171"></td>
                            </tr>
                        </tbody>
                    </table></div>
                    <p>&nbsp;</p>
                    <p><strong><u>Article 4&nbsp;: opposition ou suppression des Cookies</u></strong></p>
                    <p>Sur les ordinateurs, Smartphones et autres terminaux d’accès à Internet, par défaut le logiciel de navigation accepte les Cookies présents sur les Sites internet.</p>
                    <p>Cependant, l’Utilisateur peut gérer l’installation des Cookies en configurant le logiciel de navigation utilisé afin notamment&nbsp;de&nbsp;:</p>
                    <ul>
                        <li>refuser systématiquement tous les Cookies</li>
                        <li>demander à ce que le consentement soit requis pour chacun des Cookies rencontrés lors de la navigation sur internet.</li>
                    </ul>
                    <p>Pour ce faire, il suffit de se reporter aux politiques de configuration de chaque logiciel de navigation et de suivre leurs instructions.</p>
                    <p>Pour information, au jour de l’élaboration de la présente politique gestion des Cookies, pour les principaux logiciels de navigation :</p>
                    <ul>
                        <li>Avec Internet Explorer: menu Outils - Options internet - Onglet Confidentialité - choisissez le niveau souhaité</li>
                        <li>Avec Firefox : menu Outils - Options - Onglet Vie privée - choisissez les options souhaitées</li>
                        <li>Avec Chrome: menu de configuration (logo paramètres) - Paramètres - Paramètres avancés - Paramètres de contenu - Choisissez les options souhaitées</li>
                    </ul>
                    <p>Il est également possible de contrôler le dépôt des Cookies sur les smartphone dans les règles du système d’exploitation.</p>
                    <ul>
                        <li>Sur iOS : <a href="https://support.apple.com/fr-fr/HT201265">https://support.apple.com/fr-fr/HT201265</a>
                        </li>
                        <li>Sur Android : <a href="https://support.google.com/chrome/topic/3434352">https://support.google.com/chrome/topic/3434352</a>
                        </li>
                    </ul>
                    <h4>
                        <strong><u>Article 5&nbsp;: </u></strong><a href="https://www.haas-avocats.com/politique-des-cookies/#1527165820917-3c7d5fb7-bd1d"><span><strong>durée de conservation</strong></span></a>
                    </h4>
                    <p>Les Cookies stockés sur le terminal de l’Utilisateur ou tout autre élément utilisé pour l’identifier à des fins de statistiques d’audience ou publicitaires auront une durée de vie limitée à 13 mois maximum.</p>
                    <h4>
                        <a href="https://www.haas-avocats.com/politique-des-cookies/#1527165820997-286c2b10-67b2"><span><strong>Article</strong></span></a><span><strong><u> 6&nbsp;: droit des Utilisateurs</u></strong></span>
                    </h4>
                    <p>Outre le droit de revenir à tout moment sur le consentement donné à ce que tel ou tel Cookie soit déposé sur le terminal, l’Utilisateur dispose d’un droit d’accès, de rectification, d’effacement ou de portabilité des données.</p>
                    <p>L’Utilisateur peut s’opposer au traitement de de ses données (ou en demander la limitation).</p>
                    <p>Ces droits peuvent être exercées par tout moyen écrit auprès de&nbsp;:</p>
                    <p>NOVACYB</p>
                    <p>6, chemin de la Raude</p>
                    <p>69160 Tassin la Demi-Lune</p>
                    <p><a href="mailto:contact@novacyb.com">contact@novacyb.com</a></p>
                    <p>L’Utilisateur doit justifier de son identité lors de sa demande.</p>
                    <p><strong><u>Article 7&nbsp;: informations complémentaires</u></strong></p>
                    <p>Pour toutes informations complémentaires et légale, l’Utilisateur peut se rendre sur le Site de la CNIL : <a href="https://www.cnil.fr/fr/Site-web-cookies-et-autres-traceurs">https://www.cnil.fr/fr/Site-web-cookies-et-autres-traceurs</a></p>
            </div>
    </>
  );
}

export default CookiePolicy;