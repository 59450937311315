import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import "./Hero.css";

//Images
import logoNovacybLarge from '../../assets/images/FullTitle.png';

//Videos
import backgroundVideo from '../../assets/videos/Tech_Animated.mp4';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

    return (
    <section
      {...props}
      className={outerClasses}
    >      
      <div className="container-sm">
      <video
              autoPlay
              loop
              muted
              style={{
                position: "absolute",
                width: "1128px",
                left: "50%",
                top:"50%",
                height:"100%",
                objectFit: "cover",
                transform: "translate(-50%, -50%)",
                zIndex: "-1",
                opacity:"100%"

              }}
              >
                <source src={backgroundVideo} type="video/mp4" />

            </video>
        <div className={innerClasses}>
          <div className="hero-content">
          
          <img className="mainLogoNovacyb" src={logoNovacybLarge} alt="Logo Novacyb"/>
          </div>
        </div>
      </div>  
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;