import React, { useState } from 'react';
import './styles/Login.css';
import Hero from '../components/sections/Hero';
import { Parallax } from 'react-parallax';
import PropTypes from 'prop-types';

//Images
import loginBgImage from '../assets/images/paralla/fractal.jpg';

//API URL
const API_LOGIN = 'http://localhost:8080/login'

//Gestion du formulaire - 04-03-2021
async function loginUser(credentials) {
    return fetch(API_LOGIN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(res => {
          if(res.status >= 400) {
              throw new Error ("Server responds with error !");
          }
          return res.json();
         })
}


export default function Login ({setToken}) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
          email,
          password
        });
        setToken(token);
      }

    return (
        <>
        <Hero className="illustration-section-01" />  
        <Parallax 
            blur={{ min: 15, max: -15 }} 
            bgImageStyle={{
                opacity:'.4',
                width:'100%'}}  
            bgImage={loginBgImage} 
            bgImageAlt="Fond Tech Parallax" 
            strength={300}
        >
            <div className="login-wrapper">
                <form className="signUpForm" onSubmit={handleSubmit}>
                    <h2>Se connecter</h2>
                        <fieldset>
                            <label className="login-labelStyle">
                                <p>Adresse email</p>
                                <input 
                                    type="email" 
                                    className="login-form-control" 
                                    placeholder="Entrez votre email" 
                                    onChange={e => setEmail(e.target.value)}
                                /> 
                            </label>                  
                            <label className="login-labelStyle">
                                <p>Mot de passe</p>
                                <input 
                                    type="password" 
                                    className="login-form-control" 
                                    placeholder="Entrez votre mot de passe" 
                                    onChange={e => setPassword(e.target.value)}
                                /> 
                            </label>
                            <p className="forgot-password">
                                Mot de passe oublié ? <a href="#">Cliquez ici !</a>
                            </p>
                            <div>
                                <button type="submit" className="btnSbmit">Se connecter</button>
                            </div>
                        </fieldset>
                </form>
                <p className="account-already">
                        Vous n'avez pas encore de compte ? <a href="/Register">S'inscrire ici.</a>
                </p>
            </div>
        </Parallax>
        </>
    );
}

Login.propTypes = {
setToken: PropTypes.func.isRequired
}