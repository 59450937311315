import React from 'react';
import Hero from '../components/sections/Hero';
import Cta from '../components/sections/Cta';
import {Link} from 'react-router-dom';
import "./styles/Error.css";


const Error = () => {

  return (
    <>
      <Hero className="illustration-section-01" />  
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2 className="notfound-oups">Oups !</h2>
          <p>La page que vous recherchez peut avoir été supprimée, son nom a peut-être changé ou est temporairement indisponible.</p>
          <Link to="/" className="linkStyle"> Retourner à la page d'accueil</Link>
        </div>
      </div>
    </>
  );
}

export default Error;