import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link to="/Contact">Contact</Link>
        </li>
        <li>
          <Link to="/Prices">Tarifs</Link>
        </li>
        <li>
          <Link to="/AboutUs">Novacyb</Link>
        </li>
        <li>
          <Link to="/FAQ">FAQ</Link>
        </li>
        <li>
          <Link to="/Support">Support</Link>
        </li>
        <li>
          <Link to="/Mentions">Mentions Légales</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;